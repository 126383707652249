import "./src/globalStyles/global.css";

export const onServiceWorkerUpdateReady = () => {
  console.log("Update Service Worker");
  localStorage.clear();
  window.location.reload();
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  );
  if (answer === true) {
    localStorage.clear();
    window.location.reload();
  }
};

export const registerServiceWorker = () => true;
